import { useSelector } from "react-redux";
import { AppState } from "../../store";
import getLanguage from "../../utils/getLanguage";

const userLanguage = (): string => {
  const language = useSelector<AppState, string>(state => state.user.language);
  return language || getLanguage();
};

export default userLanguage;
