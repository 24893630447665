import React, { FC, ReactNode } from "react";
import { ThemeProvider as OThemeProvider } from "styled-components";

export interface Fonts {
  main: string;
}

export interface Colors {
  primary: string;
  secondary: string;
  error: string;
  dark: string;
  grey: string;
  lightBlue: string;
  white: string;
  success: string;
  primaryOpacity: string;
  lightGrey: string;
  green: string;
  border: string;
  placeholder: string;
  primaryText: string;
  secondaryText: string;
  mapExtraMuros: string;
  mapIntraMuros: string;
  mapHover: string;
}

export interface Breakpoints {
  phone: string;
  phoneValue: number;
  tablet: string;
  tabletValue: number;
  desktop: string;
  desktopValue: number;
}

export interface Theme {
  fonts: Fonts;
  colors: Colors;
  breakpoints: Breakpoints;
}

export interface Props {
  children: ReactNode;
  theme?: Theme;
}

const defaultTheme: Theme = {
  fonts: {
    main: "Lato, sans-serif",
  },
  colors: {
    primary: "#112631",
    secondary: "#31aded",
    error: "#960000",
    dark: "#252c32",
    grey: "#f5f5f5",
    lightBlue: "#d3eaf6",
    white: "#ffffff",
    success: "#3d9600",
    primaryOpacity: "rgba(17, 38, 49, 0.8)",
    lightGrey: "#787878",
    green: "#47d554",
    border: "#d7d7d7",
    placeholder: "#323232",
    primaryText: "#313131",
    secondaryText: "#909090",
    mapExtraMuros: "#31aded80",
    mapIntraMuros: "#31aded",
    mapHover: "#1580B6",
  },
  breakpoints: {
    phone: "(min-width: 750px)",
    phoneValue: 750,
    tablet: "(min-width: 1024px)",
    tabletValue: 1024,
    desktop: "(min-width: 1200px)",
    desktopValue: 1200,
  },
};

const ThemeProvider: FC<Props> = ({
  children,
  theme = defaultTheme,
}: Props) => <OThemeProvider theme={theme}>{children}</OThemeProvider>;

export { defaultTheme as theme };
export default ThemeProvider;
