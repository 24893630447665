import styled, { keyframes, Keyframes } from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  bottom: 8px;
  display: flex;
  justify-content: center;
  width: 95%;
  margin: 0 2.5%;
  z-index: 9999999;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    top: 30px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    top: 0px;
  }
  to {
    opacity: 0;
    top: 30px;
  }
`;

export enum TOAST_TYPE {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

interface ToastProps {
  out?: boolean;
  type?: TOAST_TYPE;
}

export const Toast = styled.div<ToastProps>`
  animation: ${({ out }): Keyframes => (out ? fadeOut : fadeIn)} ease-in-out
    300ms;
  background-color: ${({ type }): string => {
    switch (type) {
      case TOAST_TYPE.ERROR:
        return "rgba(150, 0, 0, 0.95)";
      case TOAST_TYPE.SUCCESS:
        return "rgba(61, 150, 0, 0.95)";
      case TOAST_TYPE.WARNING:
        return "rgba(255, 193, 7, 0.95)";
      default:
        return "rgba(100, 100, 100, 0.95)";
    }
  }};
  padding: 8px 16px;
  border-radius: 8px;
  color: ${({ theme, type }): string => type === TOAST_TYPE.WARNING ? theme.colors.black : theme.colors.white };
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  box-sizing: border-box;
  z-index: 99999;
`;

export default Toast;
