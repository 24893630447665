import { getCookie } from "react-use-cookie";

const getLanguage = (): string => {
  let userLang = "fr";
  if (typeof window !== "undefined") {
    const systemLang = getCookie("userLanguage") || navigator.language;

    if (["fr", "en", "fr-FR", "en-US", "en-GB"].includes(systemLang)) {
      userLang = ["fr", "fr-FR"].includes(systemLang) ? "fr" : "en";
    }
  }

  return userLang;
};

export default getLanguage;
